<template>
    <div class="achievement" v-if="awards && awards.length">
        <div class="achievement-title">Досягнення партійця</div>
        <div class="list">
            <div class="image-container" v-for="(award, index) in awards" :key="index">
                <img v-if="+award.system_id === 1" :title="award.name" src="../assets/images/bee_1.svg" :alt="award.name">
                <img v-if="+award.system_id === 2" :title="award.name" src="../assets/images/bee_2.svg" :alt="award.name">
                <img v-if="+award.system_id === 3" :title="award.name" src="../assets/images/bee_3.svg" :alt="award.name">
                <img v-if="+award.system_id === 4" :title="award.name" src="../assets/images/bee_4.svg" :alt="award.name">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AchievementParticipant",
    props:{
        awards: {
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
    .achievement{
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .achievement-title{
            font-size: 12px;
            font-weight: 500;
            color: #1a2744;
        }
        .list{
            display: flex;
            margin-top: 20px;
            .image-container{
                margin-right: 10px;
            }
        }
    }
</style>
