<template>
    <div class="detail-user-container">
        <div class="cards-wrapper">
            <CommonBlock
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                @clickBack="clickBack"
            >
                <ParticipantDetailCard v-if="isUserLoaded" :detailUser="detailUser"/>
                <AchievementParticipant v-if="isUserLoaded" :awards="detailUser.user.awards"/>
            </CommonBlock>
        </div>
        <DetailParticipantMain v-if="isUserLoaded" :user="detailUser"></DetailParticipantMain>
    </div>
</template>

<script>
import DetailParticipantMain from "../components/DetailParticipantMain";
import CommonBlock from "../components/CommonBlock";
import ParticipantDetailCard from "../components/ParticipantDetailCard";
import AchievementParticipant from "../components/AchievementParticipant";
import UserService from "@/services/UserService";

export default {
    name: "DetailParticipant",
    components: {
        DetailParticipantMain,
        CommonBlock,
        ParticipantDetailCard,
        AchievementParticipant
    },
    data(){
      return {
            detailUser: {},
            isUserLoaded: false,
            breadcrumbs: [
                {id: 1, name: 'Члени партії', link: '/cards-participant'},
                {id: 2, name: 'Один партієць'}
            ],
            achievementList: [
                {title: 'Я — активний учасник партійних заходів', achievementStatus: 2},
                {title: 'Я —  медійно активний', achievementStatus: 3},
                {title: 'Я реалізую партійні проекти', achievementStatus: 4},
            ]
      }
    },
    methods:{
        clickBack(){
            this.$router.go(-1);
        },
        async getDetailUser() {
            let resp = await UserService.getParticipantsById(+this.$route.params.id).catch(this.handleError);
            this.detailUser = resp.data;
            this.isUserLoaded = true;
        },
    },
    created() {
        this.getDetailUser()
    }
}
</script>

<style scoped lang="less">
    .detail-user-container{
        display: flex;
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
    .cards-wrapper{
        width: 33.6%;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    ::v-deep .common-block{
        width: 100%;
    }
    ::v-deep .breadcrumb li a{
        color: #1a2744;
    }

    .common-block.deputies{
        @media (max-width: 992px){
            min-height: auto;
        }
        ::v-deep{
            .breadcrumb li a{
                color: #ffc900;
            }
            ul li:not(:first-child):before{
                background: #ffc900;
            }
            ul li:last-child:before{
                background: white;
            }
            .body{
                .user-name{
                    color: white;
                }
                .user-main-item{
                    .title{
                        color: white;
                    }
                    .description{
                        color: #ffc900;
                    }
                }
            }
        }
    }
    .common-block{
        height: 100%;
    }
    ::v-deep{
        .common-block{
            height: 100%;
        }
    }
    @media (max-height: 992px){
        .cards-wrapper{
            height: auto;
        }
        .common-block{
            min-height: auto!important;
        }
        ::v-deep{
            .common-block{
                min-height: auto!important;
            }
        }
    }
</style>
