
import handleApiError from "@/services/handleApiError";
import axios from "axios";

export default {
    getDeputies(limit = 20, offset = 0, searchParams = {}) {
        let countParams = {
            limit: limit,
            offset: offset,
            not_himself: 0
        }
        let params = Object.assign(countParams, searchParams);

        return axios.get('/api/user/deputies', { params: params }).then(resp => resp.data.data).catch(handleApiError)
    },
    getParticipantsById(id) {
        return axios.get(`/api/user/get-participant-info-by-id`, { params: { id } }).then(resp => resp.data).catch(handleApiError)
    },
    getParticipants(limit = 20, offset = 0, searchParams = {}) {
        let countParams = {
            limit: limit,
            offset: offset,
            not_himself: 0
        }
        let params = Object.assign(countParams, searchParams);

        return axios.get('/api/user/deputies?is_deputy=0', { params: params }).then(resp => resp.data.data).catch(handleApiError)
    },
    update(data) {
        return axios.post('/api/user/update', data).then(resp => resp.data).catch(handleApiError)
    },
    getFilter(type) {
        return axios.get('/api/user/filter', { params: { type: type } }).then(resp => resp.data.data).catch(handleApiError)
    },
    getDeputy(id) {
        return axios.get('/api/user/deputy', { params: { id: id } }).then(resp => resp.data.data).catch(handleApiError)
    },
    getRating(id, level = null) {
        let params = { type: id };
        if(level) {
            params['level'] = level;
        }
        return axios.get('/api/user/rating', { params: params }).then(resp => resp.data.data).catch(handleApiError)
    },
    getRatingDetails(id) {
        return axios.get('/api/user/rating-details', { params: { user_id: id } }).then(resp => resp.data.data).catch(handleApiError)
    },
    getRatingActivity() {
        return axios.get('/api/user/rating-activity', { }).then(resp => resp.data.data).catch(handleApiError)
    },
    getActivityList(){
        return axios.get('/api/job-type').then(resp => resp.data.data).catch(handleApiError)
    },
    getPartyOrganization(){
        return axios.get('/api/party-organization').then(resp => resp.data.data).catch(handleApiError)
    }
}
