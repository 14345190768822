<template>
    <div class="achievement-detail">
        <div class="border">
            <div class="top">
                <div class="item" v-if="awards.achievementItems" v-for="(achievement, index) in awards.achievementItems" :key="index">
                    <div class="title">{{ awards.title }}</div>
                    <div class="container">
                        <div class="achievement-list">
                            <div class="achievement-img" v-if="achievement">
                                <span v-for="(t,index) in 4" :key="index">
                                    <img src="@/assets/images/bee_1.svg" v-if="t === 1 && achievement.type === t" :alt="achievement.name">
                                    <img src="@/assets/images/bee_2.svg" v-else-if="t === 2 && achievement.type === t" :alt="achievement.name">
                                    <img src="@/assets/images/bee_3.svg" v-else-if="t === 3 && achievement.type === t" :alt="achievement.name">
                                    <img src="@/assets/images/bee_4.svg" v-else-if="t === 4 && achievement.type === t" :alt="achievement.name">
                                    <img src="@/assets/images/bee_empty.svg" v-else alt="">
                                </span>
                            </div>
                            <div class="achievement-img" v-else>
                                <img src="@/assets/images/bee_empty.svg" alt="">
                                <img src="@/assets/images/bee_empty.svg" alt="">
                                <img src="@/assets/images/bee_empty.svg" alt="">
                                <img src="@/assets/images/bee_empty.svg" alt="">
                            </div>

                        </div>

                    </div>
                </div>
                <div class="friends">
                    <div class="title">
                        Залучено друзів
                    </div>
                    <div class="counter">
                        <div class="icon">
                            <span class="material-icons">person</span>
                        </div>
                        <div class="number">{{ user.invited }}</div>
                    </div>
                </div>
            </div>
            <div class="projects">
                <div class="title">
                    Мої проекти
                </div>
                <div class="projects-list">
                    <div class="item">
                        <div class="circle"></div>
                        <div class="description">
                            <div class="text">Подано з моєї ініціативи</div>
                            <div class="number">{{ +user.projects['created'] }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="circle green"></div>
                        <div class="description">
                            <div class="text">Залучення за ініціативи іншого партійця</div>
                            <div class="number">{{ +user.projects['co-create'] }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="circle purple"></div>
                        <div class="description">
                            <div class="text">Поставлено на голосування</div>
                            <div class="number">{{ +user.projects['in_progress'] }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="circle blue"></div>
                        <div class="description">
                            <div class="text">Прийнято</div>
                            <div class="number">{{ +user.projects['accepted'] }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="diagram">
                <div class="title">
                    Моя медійна діяльність (модуль в стані розробки)
                </div>
                <img src="../assets/images/345.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import InviteService from "../services/InviteService";

export default {
    name: "DetailParticipantMain",
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            awards: {
                title: 'Я — активний учасник партійних заходів',
                achievementStatus: 1,
                achievementItems: []
            },
        }
    },
    computed: {
        // achievementList() {
        //     let data = [
        //         {title: 'Я — активний учасник партійних заходів', award_type: 1},
        //         // {title: 'Я — медійно активний', award_type: 3},
        //         // {title: 'Я реалізую партійні проекти', award_type: 4},
        //     ];
        //
        //     return data.map(item => {
        //         item['award'] = this.awards.find(award => +award.type === item.award_type);
        //         return item;
        //     })
        // },
        // participantAward() {
        //     return this.awards.find(award => +award.type === 1);
        // },
        // mediaAward() {
        //     return this.awards.find(award => +award.type === 3);
        // }
    },
    methods:{
        clickBack(){
            this.$router.go(-1);
        },
        
    },
}
</script>

<style scoped lang="less">

.border{
    padding: 20px;
    margin: 40px;
    margin-top: 60px;
    background-color: white;
    border-radius: 10px;
}
.achievement-detail{
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    .diagram{
        .title{
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            color: #1a2744;
            margin-bottom: 30px;
            font-family: 'Fira Sans', sans-serif;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .projects{
        .title{
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.17px;
            color: #1a2744;
            font-family: 'Fira Sans', sans-serif;
            margin-bottom: 30px;
        }
        .projects-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item{
                width: 265px;
                height: 120px;
                border-radius: 10px;
                border: solid 1px #f5f5f5;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                .circle{
                    width: 60px;
                    height: 60px;
                    margin: 0 20px 0 0;
                    border-radius: 30px;
                    border: solid 1px #f5f5f5;
                    background-color: #dfc365;
                }
                .circle.green{
                    background-color: #3dea99;
                }
                .circle.purple{
                    background-color: #b601d9;
                }
                .circle.blue{
                    background-color: #18b4f8;
                }
                .description{
                    width: calc(100% - 80px);
                    .text, .number{
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: #1a2744;
                    }
                    .number{
                        font-size: 32px;
                        font-weight: bold;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 992px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
        .title{
            font-size: 24px;
            font-weight: 500;
            color: #1a2744;
            font-family: 'Fira Sans', sans-serif;
            margin-bottom: 30px;
        }
        .counter{
            display: flex;
            .icon{
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 52px;
                height: 52px;
                background-color: #ffc900;
                border-radius: 50%;
            }
            .number{
                margin-left: 20px;
                font-size: 56px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #1a2744;
                font-family: 'Fira Sans', sans-serif;
            }

        }
    }
    .item{
        width: 77%;
        margin-bottom: 60px;

    }
    .container{

    }
}
.achievement-img{
    height: 50px;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    background: url(../assets/images/points-achievement.svg)repeat-x center center;
}
.achievement-list{
    padding: 0 4px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
}


@media (max-height: 992px){
    .cards-wrapper{
        height: auto;
    }
    .common-block{
        min-height: auto!important;
    }
    ::v-deep{
        .common-block{
            min-height: auto!important;
        }
    }
}
.common-block{
    min-height: auto!important;
    height: 100%!important;
}
::v-deep{
    .common-block{
        min-height: auto!important;
    }
}

</style>

