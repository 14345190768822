<template>
    <div class="participant-container" v-if="detailUser">
        <div class="user">
            <div class="user-photo" v-if="detailUser.user.photo">
                <img :src="detailUser.user.photo" alt="user photo">
            </div>
            <div class="user-name">{{ detailUser.user.first_name}} {{detailUser.user.last_name}}</div>
        </div>
        <div class="user-main">
            <div class="user-main-item"
                v-if="detailUser.user.youtube || detailUser.user.twitter || detailUser.user.facebook || detailUser.user.telegram || detailUser.user.instagram"
            >
                <div class="title">Соціальні мережі</div>
                <div class="description">
                    <a v-if="detailUser.user.youtube" :href="detailUser.user.youtube" class="soc-img logo-youtube"></a>
                    <a v-if="detailUser.user.twitter" :href="detailUser.user.twitter" class="soc-img logo-twitter"></a>
                    <a v-if="detailUser.user.facebook" :href="detailUser.user.facebook" class="soc-img logo-facebook"></a>
                    <a v-if="detailUser.user.telegram" :href="detailUser.user.telegram" class="soc-img logo-telegram"></a>
                    <a v-if="detailUser.user.instagram" :href="detailUser.user.instagram" class="soc-img logo-instagram"></a>
                </div>
            </div>
            <div class="user-main-item" v-if="detailUser.user.phone">
                <div class="title">Номер телефону</div>
                <div class="description">{{detailUser.user.phone}}</div>
            </div>
            <div class="user-main-item" v-if="detailUser.user.email">
                <div class="title">Електронна адреса</div>
                <div class="description">{{detailUser.user.email}}</div>
            </div>
            <div class="user-main-item" v-if="detailUser.user.area">
                <div class="title">Назва області/міста/села або селища</div>
                <div class="description">{{detailUser.user.area}}</div>
            </div>
            <div class="user-main-item" v-if="detailUser.user.council">
                <div class="title">Територіальна партійна організація</div>
                <div class="description">{{detailUser.user.council}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ParticipantDetailCard",
    props: ['detailUser']
}
</script>

<style scoped lang="less">
    .participant-container{
        .logo-twitter{
            mask: url('../assets/images/twitter-bl.svg') no-repeat center;
            width: 20px;
            height: 20px;
            background-color: #1a2744;
        }
        .logo-youtube{
            mask: url('../assets/images/youtube-bl.svg') no-repeat center;
            width: 20px;
            height: 20px;
            background-color: #1a2744;
        }
        .logo-facebook{
            mask: url('../assets/images/facebook-bl.svg') no-repeat center;
            width: 20px;
            height: 20px;
            background-color: #1a2744;
        }
        .logo-instagram{
            mask: url('../assets/images/instagram-bl.svg') no-repeat center;
            width: 20px;
            height: 20px;
            background-color: #1a2744;
        }
        .logo-telegram{
            mask: url('../assets/images/telegram-bl.svg') no-repeat center;
            width: 20px;
            height: 20px;
            background-color: #1a2744;
        }
        .soc-img{
            margin-right: 20px;
            cursor: pointer;
            &:hover{
                background-color: white;
            }
        }
        .user{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .user-photo img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
            .user-name{
                margin-left: 20px;
                font-family: @secondFontFamily;
                font-size: 24px;
                font-weight: 500;
                line-height: 1.33;
                letter-spacing: -0.17px;
                color: #1a2744;
            }
        }
        .user-main{
            .user-main-item{
                border-bottom: 1px solid #D8AA00;
                .title{
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.33;
                    color: #1a2744;
                    padding-top: 21px;
                    padding-bottom: 5px;
                }
                .description{
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    color: #1a2744;
                    padding-bottom: 10px;
                }
            }
        }
    }
</style>
